<template>
  <el-form>
    <div class="variableDropdown">
      <el-select
        v-if="cavVariables.length > 0"
        class="var-type-select"
        :placeholder="__('Studio')"
        :current_select="currentTypeSelection"
        @change="handleTypeChange"
        :value="currentTypeSelection"
        style="width: 10rem;"
      >
        <el-option
          :label="VARIABLE_TYPES.STUDIO_LABEL"
          :value="VARIABLE_TYPES.STUDIO"
        ></el-option>
        <el-option
          :label="VARIABLE_TYPES.INTERACTION_LABEL"
          :value="VARIABLE_TYPES.INTERACTION"
        ></el-option>
      </el-select>
      <create-or-select
        ref="studioVariableDropdown"
        v-if="currentTypeSelection === VARIABLE_TYPES.STUDIO"
        :items="studioVariablesOptions"
        label="variable_name"
        value="variable_id"
        :placeholder="__('Select or create a new variable')"
        :new-item-message="__('new variable')"
        :current_select="currentSelect"
        @change="handleStudioChange"
      />
      <create-or-select
        ref="cavVariableDropdown"
        v-if="currentTypeSelection === VARIABLE_TYPES.INTERACTION"
        :items="cavVariableItems"
        label="variable_name"
        value="variable_id"
        :placeholder="__('Select or create a new interaction variable')"
        :new-item-message="__('new variable')"
        :current_select="currentSelect"
        @change="handleCavChange"
        :allow-create="false"
      />
    </div>
    <el-col :span="4">
      <span
        class="row-message"
        v-if="isNewVariableCreated && showNewVariableMessage"
        >{{ __("new variable") }}</span
      >
    </el-col>
  </el-form>
</template>

<script>
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect.vue";
import { VARIABLE_TYPES } from "@/constants/nodes";
import { mapGetters } from "vuex";
import _ from "lodash";
import { isString } from "@/utils/validate";

export default {
  name: "VariableDropdown",
  components: {
    CreateOrSelect
  },

  props: {
    currentSelect: {
      required: true,
      type: [String, Number]
    },
    nodeData: {
      required: false,
      type: Object
    },
    variableRule: {
      required: true,
      type: String
    },
    studioVariables: {
      required: false,
      type: Array
    },
    useChangeEvent: {
      required: false,
      type: Boolean,
      default: false
    },
    showNewVariableMessage: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  computed: {
    VARIABLE_TYPES() {
      return VARIABLE_TYPES;
    },
    ...mapGetters("variables", {
      cavVariables: "cavVariables",
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables"
    }),
    nodeStudioVariables() {
      if (typeof this.nodeData === "undefined") {
        return [];
      }
      return this.nodeData.data.variable_rules.data;
    },
    nodeCavVariables() {
      if (typeof this.nodeData === "undefined") {
        return [];
      }
      return this.nodeData.data.interaction_variable_rules.data;
    },
    studioVariablesOptions() {
      return this.studioVariables
        ? this.studioVariables
        : this.singleValuedAndSecureVariables;
    },
    cavVariableItems() {
      return this.cavVariables.map(variable => {
        return {
          ...variable,
          variable_id: variable.variable_id.toString() + "|cav"
        };
      });
    }
  },
  data() {
    return {
      currentTypeSelection: this.getCurrentTypeSelection(),
      isNewVariableCreated: false
    };
  },

  methods: {
    getCurrentTypeSelection() {
      if (isString(this.currentSelect) && this.currentSelect.endsWith("|cav")) {
        return VARIABLE_TYPES.INTERACTION;
      }
      return VARIABLE_TYPES.STUDIO;
    },
    handleTypeChange(variableType) {
      this.$set(this, "currentTypeSelection", variableType);
    },
    handleStudioChange(option) {
      if (this.useChangeEvent) {
        this.$emit("change", option);
        return;
      }
      if (option.value === -1) {
        const variableRuleInitialize = {
          rule_value: this.variableRule,
          variable_name: option.label,
          variable_id: -1,
          default_value: "",
          array_variable: false
        };
        const newNodeStudioVariables = this.nodeStudioVariables.map(
          variable => {
            if (
              variable.variable_id === this.currentSelect &&
              variable.rule_value === this.variableRule
            ) {
              return variableRuleInitialize;
            }
            return variable;
          }
        );
        if (
          isString(this.currentSelect) &&
          this.currentSelect.endsWith("|cav")
        ) {
          _.remove(
            this.nodeCavVariables,
            obj =>
              obj.interaction_variable_id === this.currentSelect &&
              obj.rule_value === this.variableRule
          );
          newNodeStudioVariables.push(variableRuleInitialize);
        }
        if (newNodeStudioVariables.length === 0) {
          newNodeStudioVariables.push(variableRuleInitialize);
        }
        this.$emit(
          "updateVariables",
          newNodeStudioVariables,
          this.nodeCavVariables
        );
        this.$set(this, "isNewVariableCreated", true);
        return;
      }
      if (option.value !== this.currentSelect) {
        if (
          isString(this.currentSelect) &&
          this.currentSelect.endsWith("|cav")
        ) {
          const oldVariable = _.find(
            this.nodeCavVariables,
            obj => obj.interaction_variable_id === this.currentSelect
          );
          _.remove(
            this.nodeCavVariables,
            obj => obj.interaction_variable_id === this.currentSelect
          );
          delete oldVariable.interaction_variable_id;
          delete oldVariable.interaction_variable_rule_id;
          const newNodeStudioVariables = _.cloneDeep(this.nodeStudioVariables);
          newNodeStudioVariables.push({
            ...oldVariable,
            variable_id: option.value,
            variable_name: option.label
          });
          this.$emit(
            "updateVariables",
            newNodeStudioVariables,
            this.nodeCavVariables
          );
        }
        if (!isString(this.currentSelect)) {
          const newNodeStudioVariables = this.nodeStudioVariables.map(
            variable => {
              if (
                variable.variable_id === this.currentSelect &&
                variable.rule_value === this.variableRule
              ) {
                return {
                  ...variable,
                  variable_id: option.value
                };
              }
              return variable;
            }
          );
          this.$emit(
            "updateVariables",
            newNodeStudioVariables,
            this.nodeCavVariables
          );
        }
        if (this.currentSelect === "") {
          const variableRuleInitialize = {
            rule_value: this.variableRule,
            variable_name: option.label,
            variable_id: option.value,
            default_value: "",
            array_variable: false
          };
          this.nodeStudioVariables.push(variableRuleInitialize);
          this.$emit(
            "updateVariables",
            this.nodeStudioVariables,
            this.nodeCavVariables
          );
        }
      }
    },
    handleCavChange(option) {
      if (this.useChangeEvent) {
        this.$emit("change", option);
        return;
      }
      if (option.value === -1) {
        return;
      }
      if (option.value !== this.currentSelect) {
        if (!isString(this.currentSelect)) {
          const oldVariable = _.find(
            this.nodeStudioVariables,
            obj => obj.variable_id === this.currentSelect
          );
          _.remove(
            this.nodeStudioVariables,
            obj => obj.variable_id === this.currentSelect
          );
          delete oldVariable.variable_id;
          const newNodeCavVariables = _.cloneDeep(this.nodeCavVariables);
          newNodeCavVariables.push({
            ...oldVariable,
            interaction_variable_id: option.value,
            variable_name: option.label
          });
          this.$emit(
            "updateVariables",
            this.nodeStudioVariables,
            newNodeCavVariables
          );
        } else if (
          isString(this.currentSelect) &&
          this.currentSelect.endsWith("|cav")
        ) {
          const newNodeCavVariables = this.nodeCavVariables.map(variable => {
            if (
              variable.interaction_variable_id === this.currentSelect &&
              variable.rule_value === this.variableRule
            ) {
              return {
                ...variable,
                interaction_variable_id: option.value
              };
            }
            return variable;
          });
          this.$emit(
            "updateVariables",
            this.nodeStudioVariables,
            newNodeCavVariables
          );
        } else if (this.currentSelect === "") {
          const variableRuleInitialize = {
            rule_value: this.variableRule,
            variable_name: option.label,
            interaction_variable_id: option.value,
            default_value: "",
            array_variable: false
          };
          this.nodeCavVariables.push(variableRuleInitialize);
          this.$emit(
            "updateVariables",
            this.nodeStudioVariables,
            this.nodeCavVariables
          );
        } else if (isString(this.currentSelect)) {
          const oldVariable = _.find(
            this.nodeStudioVariables,
            obj => obj.variable_name === this.currentSelect
          );
          _.remove(
            this.nodeStudioVariables,
            obj => obj.variable_name === this.currentSelect
          );
          delete oldVariable.variable_id;
          const newNodeCavVariables = _.cloneDeep(this.nodeCavVariables);
          newNodeCavVariables.push({
            ...oldVariable,
            interaction_variable_id: option.value,
            variable_name: option.label
          });
          this.$emit(
            "updateVariables",
            this.nodeStudioVariables,
            newNodeCavVariables
          );
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.variableDropdown {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.variableDropdown {
  ::v-deep .new-item-or-select:not(:first-child) .el-select .el-input__inner {
    border-radius: 0 4px 4px 0 !important;
  }
}
.variableDropdown {
  ::v-deep .el-select:not(:last-child) .el-input__inner {
    border-radius: 4px 0 0 4px !important;
    border-right: 0;
  }
}
</style>
